import { cisFile, cisFolder, cisImage } from '@coreui/icons-pro'
import CIcon from '@coreui/icons-react'
import { CCard, CCardBody, CCol, CPlaceholder, CPopover, CRow, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from '@coreui/react-pro'
import { IImage } from '../../types/entities/image';
import { useContext, useEffect, useState } from 'react';
import { IReportNeraca } from '../../types/entities/report';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom'
import api from '../../libs/api';
import { GenericErrorHandler } from '../../libs/misc';
import useToast from '../../hooks/toast';
import { AppContext } from '../../contexts/App';

export default function SummaryAkun() {
    const [searchParams] = useSearchParams();
    const [data, setData] = useState<IReportNeraca>()
    const [loading, setloading] = useState(true)
    const [addToast] = useToast()
    const { eye } = useContext(AppContext)

    useEffect(() => {
        setloading(true)
        let sp = createSearchParams(searchParams)
        sp.set('akun', searchParams.get('akun') || "")
        sp.set('date_after', searchParams.get('date_after') || "")
        sp.set('date_before', searchParams.get('date_before') || "")

        api.get(`/reports/summaryakun?${sp.toString()}`).then(onfulfilled => {
            setData(onfulfilled.data)
            setloading(false)
        }).catch(reason => {
            GenericErrorHandler({ reason, addToast })
        });
    }, [searchParams, addToast])

    if (loading) return (
        <CPlaceholder animation="glow">
            <CPlaceholder xs={12} size="lg" />
        </CPlaceholder>
    )
    return (<CCard>
        <CCardBody>
            <CRow className='d-md-none'>
                <CCol xs={12} className='mb-3'>
                    <span><CIcon icon={data?.is_sub ? cisFolder :  cisFile} className='me-2' />{data?.full_name}</span>
                </CCol>
                <CCol xs={12}>
                    <span className='text-success'>
                        Total Debit (Rp,-)
                    </span>
                    <span className='float-end text-success'>
                        {eye === 'close' ? '**********' : new Intl.NumberFormat('id-ID').format(data?.total_debit || 0)}
                    </span>
                </CCol>
                <CCol xs={12}>
                    <span className='text-danger'>
                        Total Kredit (Rp,-)
                    </span>
                    <span className='float-end text-danger'>
                        {eye === 'close' ? '**********' : new Intl.NumberFormat('id-ID').format(data?.total_kredit || 0)}
                    </span>
                </CCol>
                <CCol xs={12}>
                    <span>
                        Subtotal (Rp,-)
                    </span>
                    <span className='float-end'>
                        {eye === 'close' ? '**********' : new Intl.NumberFormat('id-ID').format((data?.total_debit || 0) - (data?.total_kredit || 0))}
                    </span>
                </CCol>
            </CRow>
            <CTable className='d-none d-md-table'>
                <CTableHead>
                    <CTableRow>
                        <CTableHeaderCell>Akun</CTableHeaderCell>
                        <CTableHeaderCell>Total Debit (Rp,-)</CTableHeaderCell>
                        <CTableHeaderCell>Total Kredit (Rp,-)</CTableHeaderCell>
                        <CTableHeaderCell>Subtotal (Rp,-)</CTableHeaderCell>
                    </CTableRow>
                </CTableHead>
                <CTableBody>
                    <CTableRow>
                        <CTableDataCell><CIcon icon={data?.is_sub ? cisFolder :  cisFile} className='me-2' />{data?.full_name}</CTableDataCell>
                        <CTableDataCell>
                            <span className='float-end text-success' >
                                {eye === 'close' ? '**********' : new Intl.NumberFormat('id-ID').format(data?.total_debit || 0)}
                            </span>
                        </CTableDataCell>
                        <CTableDataCell>
                            <span className='float-end text-danger' >
                                {eye === 'close' ? '**********' : new Intl.NumberFormat('id-ID').format(data?.total_kredit || 0)}
                            </span>
                        </CTableDataCell>
                        <CTableDataCell>
                            <span className='float-end' >
                                {eye === 'close' ? '**********' : new Intl.NumberFormat('id-ID').format((data?.total_debit || 0) - (data?.total_kredit || 0))}
                            </span>
                        </CTableDataCell>
                    </CTableRow>
                </CTableBody>
            </CTable>
        </CCardBody>
    </CCard>)
}

