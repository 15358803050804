import CIcon from '@coreui/icons-react'
import {CContainer, CHeader, CHeaderNav, CHeaderToggler, CImage} from '@coreui/react-pro'
import React, { useContext } from 'react'
import { cilMenu } from '@coreui/icons-pro'

import { AppContext } from '../contexts/App';
import Breadcrumb from './header.breadcrumb';
import DropdownUserLogin from './header.dropdown.user.logout';
import HeaderDropdownModeToggle from "./header.dropdown.mode.toggle";
import sygnet from '../assets/images/layout/sygnet.png'
import HeaderEyeToggle from './header.eye.toggle';

const Header = () => {
    const { sidebar: { toggleVisible } } = useContext(AppContext);

    return (
        <CHeader position="sticky" className="mb-4">
            <CContainer fluid>
                <CHeaderToggler
                    className="ps-1"
                    onClick={toggleVisible}
                >
                    <CIcon icon={cilMenu} size="lg" />
                </CHeaderToggler>
                {/*<CHeaderNav>*/}
                {/*    <CImage style={{cursor: "pointer"}} className="sidebar-brand-narrow" src={sygnet} height={24}/>*/}
                {/*</CHeaderNav>*/}
                <CHeaderNav className="ms-3">
                    <HeaderDropdownModeToggle/>
                    <HeaderEyeToggle/>
                    <DropdownUserLogin/>
                </CHeaderNav>
            </CContainer>
            <Breadcrumb/>
        </CHeader>
    )
}

export default React.memo(Header)