import {cilEye, cilEyeSlash} from "@coreui/icons-pro";
import CIcon from "@coreui/icons-react";
import {
    CNavItem
} from "@coreui/react-pro";
import { useContext } from "react";
import { AppContext } from "../contexts/App";

export default function HeaderEyeToggle() {
    const { eye, setEye } = useContext(AppContext)
    return (
        <>
            <CNavItem className="me-2">
                <CIcon style={{cursor: "pointer"}} size={'xxl'} icon={eye === 'open' ? cilEye : (eye === 'close' ? cilEyeSlash : cilEye)} className={'align-middle h-100'} onClick={() => {
                    setEye(eye === 'close' ? 'open': 'close')
                }}/>
            </CNavItem>
        </>
    )
}
