
import { CCol, CPlaceholder, CRow } from '@coreui/react-pro'
import { createColumnHelper } from '@tanstack/react-table'
import { useContext, useEffect, useMemo, useState } from 'react'
import api from '../../libs/api'
import RiTable from '../../components/Table'
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom'
import { IReportNeraca } from '../../types/entities/report'
import CIcon from '@coreui/icons-react'
import { cisFolder, cisFile } from '@coreui/icons-pro'
import useToast from '../../hooks/toast'
import {GenericErrorHandler} from "../../libs/misc";
import { AppContext } from '../../contexts/App'

export default function Table() {
    const [searchParams] = useSearchParams();
    const [data, setData] = useState<IReportNeraca[]>([])
    const [loading, setloading] = useState(true)
    const columnHelper = createColumnHelper<IReportNeraca>()
    const navigate = useNavigate();
    const [addToast] = useToast()
    const { eye } = useContext(AppContext)

    const columns = useMemo(() => [
        columnHelper.accessor(row => row, {
            id: 'number',
            cell: info => {
                const value = info.getValue()
                return (<span style={{ textDecoration: !value.is_active ? 'line-through' : '' }}><CIcon icon={value.is_sub ? cisFolder : cisFile} className='me-2' />{`${value.number} ${value.name}`}</span>)
            },
            header: () => 'Akun & Sub Akun',
            footer: "TOTAL"
        }),
        columnHelper.accessor(row => row.total_debit, {
            id: 'total_debit',
            cell: info => {
                return (<span className='float-end text-success' >
                    {eye === 'close' ? '**********' : new Intl.NumberFormat('id-ID').format(info.getValue())}
                </span>)
            },
            header: () => <span className='float-end text-success'>Total Debit (Rp,-)</span>,
            footer: () => {
                return (<span className='float-end text-success'>
                    {eye === 'close' ? '**********' : new Intl.NumberFormat('id-ID').format((data).reduce((sum, current) => sum + parseInt((current.total_debit).toString()), 0))}
                </span>)
            }
        }),
        columnHelper.accessor(row => row.total_kredit, {
            id: 'total_kredit',
            cell: info => {
                return (<span className='float-end text-danger' >
                    {eye === 'close' ? '**********' : new Intl.NumberFormat('id-ID').format(info.getValue())}
                </span>)
            },
            header: () => <span className='float-end text-danger'>Total Kredit (Rp,-)</span>,
            footer: () => {
                return (<span className='float-end text-danger'>
                    {eye === 'close' ? '**********' : new Intl.NumberFormat('id-ID').format((data).reduce((sum, current) => sum + parseInt((current.total_kredit).toString()), 0))}
                </span>)
            }
        }),
        columnHelper.accessor(row => row, {
            id: 'subtotal',
            cell: info => {
                const neraca = info.getValue()
                return (<span className='float-end' >
                    {eye === 'close' ? '**********' : new Intl.NumberFormat('id-ID').format(neraca.total_debit - neraca.total_kredit)}
                </span>)
            },
            header: () => <span className='float-end'>Subtotal (Rp,-)</span>,
            footer: () => {
                return (<span className='float-end'>
                    {eye === 'close' ? '**********' : new Intl.NumberFormat('id-ID').format((data).reduce((sum, current) => sum + parseInt((current.total_debit - current.total_kredit).toString()), 0))}
                </span>)
            }
        }),

        columnHelper.accessor(row => row, {
            id: 'mobile',
            cell: info => {
                const value = info.getValue()
                const sp = createSearchParams(searchParams)
                sp.set("parent", value.id.toString())
                return (<CRow>
                    <CCol xs={12} className='mb-3'>
                        <span style={{ textDecoration: !value.is_active ? 'line-through' : '' }}><CIcon icon={value.is_sub ? cisFolder : cisFile} className='me-2' />{`${value.number} ${value.name}`}</span>
                    </CCol>
                    <CCol xs={12}>
                        <span className='text-success'>
                            Total Debit (Rp,-)
                        </span>
                        <span className='float-end text-success'>
                            {eye === 'close' ? '**********' : new Intl.NumberFormat('id-ID').format(value.total_debit)}
                        </span>
                    </CCol>
                    <CCol xs={12}>
                        <span className='text-danger'>
                            Total Kredit (Rp,-)
                        </span>
                        <span className='float-end text-danger'>
                            {eye === 'close' ? '**********' : new Intl.NumberFormat('id-ID').format(value.total_kredit)}
                        </span>
                    </CCol>
                    <CCol xs={12}>
                        <span>
                            Subtotal (Rp,-)
                        </span>
                        <span className='float-end'>
                            {eye === 'close' ? '**********' : new Intl.NumberFormat('id-ID').format(value.total_debit - value.total_kredit)}
                        </span>
                    </CCol>
                </CRow>)
            },
            header: () => 'Mobile',
            footer: () => {
                return (<CRow>
                    <CCol xs={12}>
                        <span className='text-success'>
                            Total Debit (Rp,-)
                        </span>
                        <span className='float-end text-success'>
                            {eye === 'close' ? '**********' : new Intl.NumberFormat('id-ID').format((data).reduce((sum, current) => sum + parseInt((current.total_debit).toString()), 0))}
                        </span>
                    </CCol>
                    <CCol xs={12}>
                        <span className='text-danger'>
                            Total Kredit (Rp,-)
                        </span>
                        <span className='float-end text-danger'>
                            {eye === 'close' ? '**********' : new Intl.NumberFormat('id-ID').format((data).reduce((sum, current) => sum + parseInt((current.total_kredit).toString()), 0))}
                        </span>
                    </CCol>
                    <CCol xs={12}>
                        <span>
                            Subtotal (Rp,-)
                        </span>
                        <span className='float-end'>
                            {eye === 'close' ? '**********' : new Intl.NumberFormat('id-ID').format((data).reduce((sum, current) => sum + parseInt((current.total_debit - current.total_kredit).toString()), 0))}
                        </span>
                    </CCol>
                </CRow>)
            }
        })
    ], [columnHelper, data, searchParams, eye])


    useEffect(() => {
        setloading(true)
        let sp = createSearchParams(searchParams)
        if (!searchParams.get('parent')) sp.set('is_root', 'true')

        api.get(`/reports/neraca?${sp.toString()}`).then(onfulfilled => {
            setData(onfulfilled.data)
            setloading(false)
        }).catch(reason => {
            GenericErrorHandler({reason, addToast})
        });
    }, [searchParams, addToast])


    if (loading) return (
        <CPlaceholder animation="glow">
            <CPlaceholder xs={12} size="lg" />
        </CPlaceholder>
    )
    else return (<>
        <RiTable
            data={data}
            columns={columns}
            responsive
            footerVisible
            onclickRow={(original: IReportNeraca) => {
                if (original.is_sub) {
                    const sp = createSearchParams(searchParams)
                    sp.set("parent", original?.id?.toString() || "")
                    navigate({ search: sp.toString() })
                }else{
                    const sp = createSearchParams()
                    sp.set('akun', original.id.toString())
                    if (searchParams.get('date_after')) sp.set('date_after', searchParams.get('date_after') || "")
                    if (searchParams.get('date_before')) sp.set('date_before', searchParams.get('date_before') || "")

                    navigate({pathname:'/cashflow', search: sp.toString() })    
                }
            }}
        />
    </>)

}
