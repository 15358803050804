import { CButton, CButtonGroup, CCol, CLink, CPlaceholder, CRow, CSpinner } from '@coreui/react-pro'
import { createColumnHelper } from '@tanstack/react-table'
import { useEffect, useMemo, useState } from 'react'
import api from '../../libs/api'
import RiTable from '../../components/Table'
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom'
import { IPage } from '../../types/response/pagination'
import InfiniteScroll from 'react-infinite-scroll-component'
import CIcon from '@coreui/icons-react'
import { cidLockLocked, cisFile, cisFolder, cisPencil } from '@coreui/icons-pro'
import { IAkun } from '../../types/entities/akun'
import useToast from '../../hooks/toast'
import {GenericErrorHandler} from "../../libs/misc";

export default function Table() {
    const [searchParams] = useSearchParams();
    const [data, setData] = useState<IPage<IAkun>>()
    const [loading, setLoading] = useState(true)
    const columnHelper = createColumnHelper<IAkun>()
    const navigate = useNavigate();
    const [addToast] = useToast()
    
    const columns = useMemo(() => [
        columnHelper.accessor(row => row, {
            id: 'number',
            cell: info => {
                const row = info.getValue()
                return (<span style={{ textDecoration: !row.is_active ? 'line-through' : '' }}>
                    <CIcon icon={row.is_sub ? cisFolder : cisFile} className='me-2' />
                    {row.is_sub ? <CLink style={{ cursor: 'pointer' }} onClick={() => {
                        const sp = createSearchParams(searchParams)
                        sp.set("parent", row?.id?.toString() || "")
                        navigate({ search: sp.toString() })
                    }}>{`${row.number} ${row.name}`}</CLink> : <>{`${row.number} ${row.name}`}</>}

                    {row.is_locked ? <CIcon className='ms-2' icon={cidLockLocked} /> : <></>}
                </span>)
            },
            header: () => 'Akun & Sub Akun',
        }),
        columnHelper.accessor(row => row.description, {
            id: 'description',
            cell: info => info.getValue(),
            header: () => 'Description',
        }),
        columnHelper.accessor(row => row, {
            id: 'actions',
            cell: info => {
                const row = info.getValue()
                return (<CButtonGroup className='float-end' size='sm' role="group">
                    {[
                        { to: `edit${row.is_sub ? 'sub' : ''}/${row.id}`, icon: cisPencil },
                    ].map((x, key) => <CButton key={key} style={{ textDecoration: 'none' }} variant='outline' onClick={() => {
                        navigate(x.to)
                    }}>
                        <CIcon icon={x.icon} />
                    </CButton>)}
                </CButtonGroup>)
            },
            header: () => <></>,
        }),
        columnHelper.accessor(row => row, {
            id: 'mobile',
            cell: info => {
                const row = info.getValue()
                return (<CRow>
                    <CCol xs={12}>
                        <span style={{ textDecoration: !row.is_active ? 'line-through' : '' }}>
                            <CIcon icon={row.is_sub ? cisFolder : cisFile} className='me-2' />
                            {row.is_sub ? <CLink style={{ cursor: 'pointer' }} onClick={() => {
                                const sp = createSearchParams(searchParams)
                                sp.set("parent", row?.id?.toString() || "")
                                navigate({ search: sp.toString() })
                            }}>{`${row.number} ${row.name}`}</CLink> : <>{`${row.number} ${row.name}`}</>}

                            {row.is_locked ? <CIcon className='ms-2' icon={cidLockLocked} /> : <></>}
                        </span>
                    </CCol>
                    <CCol xs={12}>
                        {row.description}
                    </CCol>
                    <CCol xs={12}>
                        <CButtonGroup className='w-100 my-3' size='sm' role="group">
                            {[
                                { to: `edit${row.is_sub ? 'sub' : ''}/${row.id}`, icon: cisPencil, text: 'Edit' },
                            ].map((x, key) => <CButton key={key} style={{ textDecoration: 'none' }} variant='outline' onClick={() => {
                                navigate(x.to)
                            }}>
                                <CIcon icon={x.icon} className='me-2' />{x.text}
                            </CButton>)}
                        </CButtonGroup>
                    </CCol>
                </CRow>)
            },
            header: () => 'Mobile',
        })
    ], [columnHelper, navigate, searchParams])

    const fetchMoreData = () => {
        if (data?.next_page_url) api.get(data.next_page_url).then(onfulfilled => {
            setData({ ...onfulfilled.data, data: [...data.data, ...onfulfilled.data.data] })
        }).catch(reason => {
            GenericErrorHandler({reason,addToast})
        });
    };


    useEffect(() => {
        setLoading(true)
        let sp = createSearchParams(searchParams)
        if (!searchParams.get('parent')) sp.set('is_root', 'true')

        api.get(`akuns?${sp.toString()}`).then(onfulfilled => {
            setData(onfulfilled.data)
            setLoading(false)
        }).catch(reason => {
            GenericErrorHandler({reason,addToast})
        });
    }, [addToast, searchParams])


    if (loading) return (
        <CPlaceholder animation="glow">
            <CPlaceholder xs={12} size="lg" />
        </CPlaceholder>
    )
    else return (<>
        <InfiniteScroll
            dataLength={data?.data.length || 0}
            next={fetchMoreData}
            hasMore={data?.next_page_url != null}
            loader={<div className='text-center m-2'>
                <CSpinner variant="grow" size='sm' /> <b>Memuat data</b>
            </div>}
        >
            <RiTable
                data={data?.data || []}
                columns={columns}
                responsive
            // onclickRow={(original: IBukuPembantu) => {
            //     // setSelected(original)
            // }}
            />

        </InfiniteScroll>

    </>)

}
