import React, {Component, useContext, useEffect} from 'react';
import {
    CAlert,
    CButton,
    CCard,
    CCardBody,
    CCol, CForm, CFormInput,
    CImage, CLoadingButton,
    CModal,
    CModalBody,
    CModalFooter,
    CRow,
    CButtonGroup
} from "@coreui/react-pro";

import api from "../libs/api";
import {AppContext} from "../contexts/App";
import {useNavigate, useSearchParams} from "react-router-dom";
import {z} from "zod";
import {SubmitHandler, useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import demo from "../assets/images/layout/demo.svg";
import logoNegative from "../assets/images/layout/logo-negative.png";
import sygnet from "../assets/images/layout/sygnet.png";
import useToast from "../hooks/toast";
import {GenericErrorHandler} from "../libs/misc";
import {cilContrast, cilMoon, cilSun, cisPencil} from "@coreui/icons-pro";
import CIcon from "@coreui/icons-react";

export function ModalLogin(){
    const [searchParams] = useSearchParams();
    const navigate = useNavigate()
    const FormSchema = z.object({
        email: z.string().nullable().optional(),
        password: z.string().nullable().optional(),
    });

    type FormSchemaType = z.infer<typeof FormSchema>;

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
        setError,
    } = useForm<FormSchemaType>({
        resolver: zodResolver(FormSchema),
    })
    const { visibleModalLogin, setVisibleModalLogin, theme, setMode, mode  } = useContext(AppContext)
    const [addToast] = useToast()
    const onSubmit: SubmitHandler<FormSchemaType> = async (data) => {
        await api.post(`/auth/token`, data).then(response => {
            localStorage.setItem('token', JSON.stringify(response.data))
            addToast({ color: "success", message: "Login berhasil" })
            // navigate(0)

        }).catch(reason => {
            GenericErrorHandler({reason: reason, addToast: addToast, additionalErrorHandler: (reason) => {
                for (let item  in reason.response.data.errors) {
                    setError(item as ("email" | "password"), { type: 'custom', message: reason.response.data.errors[item][0] })
                }
            }})
        });
    }



    return(<CModal className={theme === 'dark' ? 'bg-dark' : 'bg-light'} portal backdrop={'static'} size='sm' alignment='center' visible={visibleModalLogin}>
        <CModalBody>
            <CForm
                id='loginform'
                onSubmit={handleSubmit(onSubmit)}
            >
                <div className="text-center mb-4 d-flex flex-column">
                    <div>
                        <CImage height={40} src={sygnet} className={'mb-2'} />
                    </div>
                    <span className={'fs-4 text-uppercase fw-bold'}>{process.env.REACT_APP_PUBLIC_SITE_NAME}</span>
                </div>
                <div className='mb-3'>
                    <CFormInput
                        type='text'
                        floatingLabel="Email"
                        placeholder="Email"
                        invalid={errors.email != null}
                        feedbackInvalid={errors.email?.message}
                        autoComplete={'username'}
                        {...register("email")}
                    />
                </div>
                <div className='mb-3'>
                    <CFormInput
                        type='password'
                        floatingLabel="Password"
                        placeholder="Password"
                        invalid={errors.password != null}
                        feedbackInvalid={errors.password?.message}
                        autoComplete={'current-password'}
                        {...register("password")}
                    />
                </div>

                <CRow>
                    <CCol md={12} className='d-grid'>
                        <CLoadingButton
                            size='lg'
                            type='submit'
                            form='loginform'
                            color='info'
                            loading={isSubmitting}
                        >
                            <span className='ms-2'>Sign In</span>
                        </CLoadingButton>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol className={'text-center'}>
                        <CButtonGroup className={'mt-4 '} size = {'sm'} >
                            <CButton color={theme === 'dark' ? 'light' : 'dark'} title={'Light Mode'} active={mode === 'light'} variant="outline" onClick={() => {
                                setMode('light')
                            }}><CIcon icon={cilSun} /> Light</CButton>
                            <CButton color={theme === 'dark' ? 'light' : 'dark'} title={'Dark Mode'} active={mode === 'dark'} variant="outline" onClick={() => {
                                setMode('dark')
                            }}><CIcon icon={cilMoon}/> Dark</CButton>
                            <CButton color={theme === 'dark' ? 'light' : 'dark'} title={'Auto Mode'} active={mode === null} variant="outline" onClick={() => {
                                setMode(null)
                            }}><CIcon icon={cilContrast} /> Auto</CButton>
                        </CButtonGroup>
                    </CCol>
                </CRow>
            </CForm>


        </CModalBody>
    </CModal>)
}